@charset "UTF-8";

/*Base*/
@import 'base/Variables';
@import 'base/Global';
@import 'base/Default';
@import 'base/BootstrapOverride';
@import 'base/Helpers';
@import 'base/VendorsModifier';
@import 'base/MainMenuTop';
@import 'base/Card';
@import 'base/CardItem';
@import 'base/MainMenuSide';
@import 'base/SidebarMenu';
@import 'base/Synopsis';
@import 'base/SynopsisPipeline';
@import 'base/Empty';
@import 'base/MapWrapperWithPolygons';
@import 'base/MapWrapperWithPoint';
@import 'base/SimpleMap';
@import 'base/CampaignForm';
@import 'base/SearchPage';
@import 'base/Toggler';
@import 'base/KanbanBoard';
@import 'base/Navbar';
@import 'base/User';

/*Controls*/
@import 'controls/AccountCreate';
@import 'controls/ActivityItem';
@import 'controls/ActivityCreate';
@import 'controls/AgentCard';
@import 'controls/AgentsWorkReport';
@import 'controls/AdvertsExpensesReport';
@import 'controls/ApartmentBuyOffer';
@import 'controls/ApartmentLeaseOffer';
@import 'controls/ApartmentLeaseOfferCreate';
@import 'controls/ApartmentRentOffer';
@import 'controls/ApartmentSaleOfferCreate';
@import 'controls/ContactCard';
@import 'controls/ContactCreate';
@import 'controls/ContractTable';
@import 'controls/CreateApartmentBuySubtask';
@import 'controls/CreateApartmentRentSubtask';
@import 'controls/CreateHouseBuySubtask';
@import 'controls/CreateHouseRentSubtask';
@import 'controls/CreateLandBuySubtask';
@import 'controls/CreateLandRentSubtask';
@import 'controls/CreateNonResidentialBuildingBuySubtask';
@import 'controls/CreateNonResidentialBuildingRentSubtask';
@import 'controls/CreateNonResidentialPremisesBuySubtask';
@import 'controls/CreateNonResidentialPremisesRentSubtask';
@import 'controls/CreateSubtaskBase';
@import 'controls/CustomerCard';
@import 'controls/CustomerCreate';
@import 'controls/CustomerDeleteConfirmation';
@import 'controls/DealsMoneyReport';
@import 'controls/HouseBuyOffer';
@import 'controls/HouseLeaseOffer';
@import 'controls/HouseLeaseOfferCreate';
@import 'controls/HouseRentOffer';
@import 'controls/HouseSaleOfferCreate';
@import 'controls/LandBuyOffer';
@import 'controls/LandLeaseOffer';
@import 'controls/LandLeaseOfferCreate';
@import 'controls/LandRentOffer';
@import 'controls/LandSaleOfferCreate';
@import 'controls/NonResidentialBuildingBuyOffer';
@import 'controls/NonResidentialBuildingLeaseOffer';
@import 'controls/NonResidentialBuildingLeaseOfferCreate';
@import 'controls/NonResidentialBuildingRentOffer';
@import 'controls/NonResidentialBuildingSaleOffer';
@import 'controls/NonResidentialBuildingSaleOfferCreate';
@import 'controls/NonResidentialPremisesBuyOffer';
@import 'controls/NonResidentialPremisesLeaseOffer';
@import 'controls/NonResidentialPremisesLeaseOfferCreate';
@import 'controls/NonResidentialPremisesRentOffer';
@import 'controls/NonResidentialPremisesSaleOffer';
@import 'controls/NonResidentialPremisesSaleOfferCreate';
@import 'controls/InviteSend';
@import 'controls/SelectMapArea';
@import 'controls/TaskItem';
@import 'controls/QueryOfferCreate';
@import 'controls/RequestCreate';
@import 'controls/RequestMenu';
@import 'controls/SubtaskCreate';
@import 'controls/OfferSubtaskMenu';
@import 'controls/BindContract';
@import 'controls/BindCustomer';
@import 'controls/TransferContract';
@import 'controls/TransferAgentWork';
@import 'controls/SendVariants';
@import 'controls/FindVariants';
@import 'controls/QuickSearch';
@import 'controls/SimpleSubtaskCreate';
@import 'controls/SimpleSubtaskEdit';
@import 'controls/SimpleSubtaskComplete';
@import 'controls/PartnerAccountCard';
@import 'controls/TasksCalendar';

/*Pages*/
@import 'pages/AccountAgent';
@import 'pages/AccountAgents';
@import 'pages/AccountDepartment';
@import 'pages/AccountDepartments';
@import 'pages/AccountNotPaid';
@import 'pages/AccountSettings';
@import 'pages/AccountDealStage';
@import 'pages/AccountDealStages';
@import 'pages/AccountPipeline';
@import 'pages/AccountPipelines';
@import 'pages/AccountDefaultPipelines';
@import 'pages/AccountTaskTypes';
@import 'pages/AccountSearchSubform';
@import 'pages/AccountSearchSubforms';
@import 'pages/Activities';
@import 'pages/AdvertCampaignAvito';
@import 'pages/AdvertCampaignN1';
@import 'pages/AdvertCampaignCian';
@import 'pages/AdvertCampaignDomclick';
@import 'pages/AdvertCampaignYandex';
@import 'pages/AdvertCampaignRecrm';
@import 'pages/AdvertCampaigns';
@import 'pages/Adverts';
@import 'pages/Apartment';
@import 'pages/BadRequest';
@import 'pages/ChangePassword';
@import 'pages/Customer';
@import 'pages/CustomerCommunications';
@import 'pages/CustomerContacts';
@import 'pages/CustomerSynopsis';
@import 'pages/Customers';
@import 'pages/Error';
@import 'pages/Forbidden';
@import 'pages/House';
@import 'pages/Land';
@import 'pages/Invite';
@import 'pages/Contracts';
@import 'pages/ContractGeneral';
@import 'pages/ContractAdverts';
@import 'pages/ContractSynopsis';
@import 'pages/ContractVariants';
@import 'pages/ContractTasks';
@import 'pages/NotFound';
@import 'pages/PasswordRecovery';
@import 'pages/PersonalAccounts';
@import 'pages/PersonalSecurity';
@import 'pages/PersonalSettings';
@import 'pages/ProfileNotifications';
@import 'pages/PropertyPictures';
@import 'pages/PropertySubtaskConditions';
@import 'pages/PropertySubtaskSynopsis';
@import 'pages/QuerySubtaskConditions';
@import 'pages/QuerySubtaskSynopsis';
@import 'pages/Advert';
@import 'pages/AdvertPictures';
@import 'pages/SignIn';
@import 'pages/Reports';
@import 'pages/Communications';
@import 'pages/NonResidentialPremises';
@import 'pages/NonResidentialBuilding';
@import 'pages/SimpleSubtasks';
